.header{
     background: $c-white; -webkit-box-shadow: 0 0 10px -3px #00000070;; -moz-box-shadow: 0 0 10px -3px #00000070;; box-shadow: 0 0 10px -3px #00000070;; min-height: 99px;
    // position: fixed; top: 0; left: 0; z-index: 50; width: 100%; transition: all 0.5s ease-in-out;

    @media (max-width: 1199.98px) {
        min-height: auto;
    }
    @media (max-width: 991.98px) {
        padding: 20px 0px;
    }
    @media (max-width: 767.98px) {
        padding: 8px 0;
    }
    // .container{
    //     transition: all 0.4s ease-in-out;
    // }

    &.stuck{
        // padding: 15px 0; -webkit-box-shadow: 0px 5px 19px 7px rgba(0,0,0,0.05); -moz-box-shadow: 0px 5px 19px 7px rgba(0,0,0,0.05); box-shadow: 0px 5px 19px 7px rgba(0,0,0,0.05);

        // .h-rgt .h-navigation{
        //     margin-bottom: 15px;
        //     @media (max-width: 1199.98px) {
        //         margin-bottom: 0;
        //     }
        // }

        // .container{
        //     max-width: 100%; transition: all 0.5s ease-in-out;
        // }
    }

    .h-lft{
        width: 28%; display: inline-block; vertical-align: middle;
        .logo{
            img{
                max-height: 40px; 
                @media (max-width: 991.98px) {
                    margin: 0 auto; height: auto; width: auto;
                }
                @media (max-width: 767.98px) {
                    margin: 0; height: auto; width: auto;
                }
            }
        }

       

        @media (max-width: 991.98px) {
            width: 33%; display: inline-block; vertical-align: middle;
        }

        @media (max-width: 767.98px) {
            width: 50%;
        }
        @media (max-width: 575.98px) {
            width: 78%;
        }
        
    }

    .h-rgt{
        width: 72%; padding-left: 32px; display: inline-block; vertical-align: middle; text-align: right;

        @media (max-width: 1399.98px) {
            padding-left: 15px;
        }
        @media (max-width: 991.98px) {
            display: inline-block; vertical-align: middle; margin-top: 0; width: 67%;
        }
        @media (max-width: 767.98px) {
            width: 50%;
        }
        @media (max-width: 575.98px) {
            width: 22%;
        }

        .h-navigation{
            // margin-bottom: 26px; 
            transition: all 0.5s ease-in-out;

            @media (max-width: 991.98px) {
                order: 2; margin-bottom: 0; text-align: center; border-top: 1px solid $c-solitude; padding-top: 10px; margin-top: 20px;
            }

            @media (max-width: 991.98px) {
                height: 0; width: 0; padding: 0; margin: 0; border: 0; left: -100%; position: fixed; -webkit-transform: translateX(-100%); -ms-transform: translateX(-100%); -o-transform: translateX(-100%); transform: translateX(-100%); -o-transition: left 0s ease-in-out; -ms-transition: left 0s ease-in-out; -moz-transition: left 0s ease-in-out; -webkit-transition: left 0s ease-in-out; transition: left 0s ease-in-out;

                &.menu-open{
                    top: 0; left: 0; z-index: 45; background-color: $c-white; height: 100vh; width: 75%; margin: 0; -webkit-box-shadow: 3px 0px 18px 8px rgba(0,0,0,0.13); -moz-box-shadow: 3px 0px 18px 8px rgba(0,0,0,0.13); box-shadow: 3px 0px 18px 8px rgba(0,0,0,0.13); -webkit-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); overflow-y: auto; 
                    
                    // nav{
                    //     position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); padding: 20px 0; width: max-content;
                    // }
                }
                
            }
            @media (max-width: 575.98px) {
                &.menu-open{
                    width: 82%;
                }
            }

            .menu-text{
                display: none;
                @media (max-width: 991.98px) {
                    padding: 12px 0; font-size: $f-18; font-weight: $weight-600; display: block; text-align: center; background-color: $c-midnight-blue; color: $c-white; text-transform: uppercase;
                }
            }
            
            ul{
                margin: 0; list-style: none;
                li{
                    display: inline-block; padding: 0 10px; line-height: 26px; line-height: inherit;

                    > &:last-child{
                        padding-right: 0;
                    }

                    @media (max-width: 1399.98px) {
                        padding: 0 8px;
                    }

                    @media (max-width: 991.98px) {
                        display: block; text-align: left; padding: 12px; border-bottom: 1px solid #00356f29;
                    }

                    .link-action{
                        text-transform: uppercase; font-size: $f-13; font-weight: $weight-700; color: $c-dark-grayish; letter-spacing: 1px; position: relative;

                        @media (max-width: 991.98px) {
                            letter-spacing: 0; font-size: 16px; color: $c-midnight-blue;
                        }

                        &:after{
                            content: ""; width: 60%; opacity: 0; height: 2px; background-color: $c-dark-grayish; position: absolute; top: auto; bottom: -4px;  transform: translateX(-50%);
                            height: 100px; width: 100px; display: block; transform: rotate(45deg); background-color: #575859; z-index: 9 !important; top: 105px;
                            @media (max-width: 1199.98px) {
                                height: 85px; width: 85px; top: 80px;
                                &:last-child{
                                    width: 0px;
                                }
                            }
                            @media (max-width: 991.98px) {
                                display: none;
                            }
                            &:last-child{
                                width: 0px;
                            }
                        }
                        
                        

                        &:hover{
                            color: $c-pacific-blue;
                            // &:after{
                            //    width: 100%; opacity: 0.8; transition: all 0.4s ease-in-out; background-color: $c-pacific-blue;
                            // }
                        }
                        &:active{
                            &:after{
                                opacity: 1 !important;
                            }
                        }
                        &:hover{
                            &:after{
                                opacity: 1 !important;
                            }
                        }
                    }
                     // Sub Navigation CSS END

                    &.separator{
                        padding: 0 15px;
                        &:last-child{
                            padding-right: 0;
                        }
                        &::before{
                            content: "";  height: 100%; width: 1px; display: block; position: absolute; left: 0; top: 0;
                            @media (max-width: 991.98px) {
                                display: none;
                            }
                        }
                        @media (max-width: 1399.98px) {
                            padding: 0 8px;
                        }

                        @media (max-width: 991.98px) {
                            border: 0; margin: 15px 0; display: inline-block; padding: 0px; border-bottom: none !important;

                            .link-action{
                                background-color: $c-midnight-blue; color: $c-white; padding: 8px 15px; border-radius: 4px; display: inline-block; width: 160px; text-align: center;
                                @media (max-width: 991.98px) {
                                    &:after,&::before{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .header.apple-header{
        .h-lft{
            width: 35%;
        }
        .h-rgt{
            width: 65%;
        }
    }
}
/*
Responsive Navigation START*/
.hamburger-menu{
    display: none; line-height: 0;

    @media (max-width: 991.98px) { 
        display: inline-block; vertical-align: middle; margin-right: 10px;
    }    
    @media (max-width: 575.98px) { 
        margin-left: 22px; margin-right: 0px;
    }
    .hamburger-icon{
        cursor: pointer; position: relative; display: inline-block;

        .bar{
            width: 30px; height: 3px; background-color: $c-midnight-blue; margin: 6px 0; transition: 0.4s; border-radius: 4px;
        }
    }

    &.open{
        .hamburger-icon{
            // z-index: 9999; position: fixed; top: 20px; right: 20px;
            .bar{
                // background-color: $c-white;
                &:nth-child(1) {
                    transform: rotate(-45deg) translate(-5px, 7px);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: rotate(45deg) translate(-5px, -8px);
                }
            }
        }
       
    }
}

.header{
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 99;
            nav{
                .navbar-level-1{
                    li{
                        .link-action{
                            padding: 42px 0; display: inline-block; position: relative; width: 100%; 
                            @media (max-width: 1199.98px) {
                                    padding: 30px 0;
                            }
                            &:last-child{
                                &::after{
                                    display: none; height: 0px;
                                }   
                            } 
                            
                            @media (max-width: 991.98px) {
                                padding: 10px 0px;
                            }   
                            @media (max-width: 575.98px) {
                                &::before{
                                    left: 93%;
                                }
                            }
                        } 
                                           
                    }
                    li{
                        @media (max-width: 767.98px) {
                            padding: 15px 12px;
                        }
                        &:hover{
                            @media (min-width: 991.98px) {
                                .fullwidth{
                                    display: block; 
                                }
                            }
                        }
                        .fullwidth{
                            display: none; 
                            position: absolute;
                            left: 0;
                            width: 100%;
                            background: #58595b;
                            z-index: 9999;
                            min-height: 375px;
                            top:99px;
                            padding: 30px;
                            @media (max-width: 1199.98px) {
                                padding: 15px 0px; top: 75px; min-height: 400px;
                            }
                            @media (max-width: 991.98px) {
                                display: block; 
                                position: inherit;
                                background: #fff;
                                z-index: 9999;
                                min-height: auto;
                                padding: 0px;
                                top: 0;
                            }
                            .navbar-level-2{
                                width: 30%; 
                                position: relative !important;  
                                @media (max-width: 1199.98px) {
                                    width: 33%;
                                } 
                                @media (max-width: 991.98px) {
                                    width: 100%;
                                } 
                                li{
                                    display: block; padding: 00px 0px; text-align: left; 
                                    @media (max-width: 1199.98px) {
                                        padding: 0px;
                                    }
                                    @media (max-width: 767.98px) {
                                        padding: 5px 0px; border-bottom: 1px solid rgb(0, 53, 111, 0.1607843137) !important;
                                    }
                                    &:hover{
                                        .navbar-level-3{
                                            display: block;  
                                        }
                                    }
                                    &:hover,&:active,&:focus{
                                        .link-action{
                                            color: #009ee2;
                                        }
                                    }
                                    &:hover,&:active{
                                        .link-action{
                                        &:before {
                                            position: absolute;
                                            content: "";
                                            right: -17px;
                                            border-right: 2px solid #009ee2;
                                            top: -8px;
                                            height: 75%;
                                            border-radius: 1px;
                                            transform: rotate(315deg);
                                            @media (max-width: 991px) {
                                                border-right: 0px !important;
                                            }
                                        }
                                        &:after {
                                            position: absolute !important;
                                            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMTIxLjgwNSAxMjEuODA0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCBkPSJNNy4zMDggNjguMjExaDEwNy4xODhhNy4zMDkgNy4zMDkgMCAwIDAgNy4zMDktNy4zMSA3LjMwOCA3LjMwOCAwIDAgMC03LjMwOS03LjMwOUg3LjMwOGE3LjMxIDcuMzEgMCAwIDAgMCAxNC42MTl6IiBmaWxsPSIjMDAzNTZmIiBvcGFjaXR5PSIxIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIj48L3BhdGg+PC9nPjwvc3ZnPg==z") !important;
                                            content: "";
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                            border-right: 2px solid #009ee2 !important;
                                            top: auto !important;
                                            height: 74% !important;
                                            width: 0px;
                                            bottom: -8px;
                                            display: block;
                                            background-color: #575859;
                                            transform: rotate(45deg);
                                            right: -17px !important;
                                            opacity: 1;
                                            @media (max-width: 991px) {
                                                border-right: 0px !important; opacity: 0; height: 0px;
                                                right: 0px;
                                            }
                                        }
                                    }
                                    }
                                    &:last-child{
                                        border-bottom: none !important;
                                    }
                                    .link-action{
                                            text-transform: capitalize; font-size: 18px; line-height: 25px; color: $c-white; position: relative; display: inline-block; width: 100%; padding: 20px 0px; padding-left: 5px;
                                            @media (max-width: 1199.98px) {
                                                padding: 20px;
                                            }
                                            @media (max-width: 991px) {
                                                color: #00356f; padding: 10px 8px;
                                            }
                                            @media (max-width: 575px) {
                                                 padding: 8px;
                                            }
                                            &:after{
                                                position: absolute !important;
                                                content: "";
                                                right: -10px !important;
                                                border-right: 2px solid #009ee2 !important; 
                                                top: 0 !important;
                                                height: 100% !important;
                                                width: 0px;  height: 2px; border-right: 2px solid #009ee2 !important;     bottom: -4px;  display: block; transform: rotate(0deg); background-color: #575859; z-index: 9 !important; top: 0;
                                                @media (max-width: 991px) {
                                                    display: none;
                                                }
                                            }
                                            &:before{
                                                position: absolute !important;
                                                content: "";
                                                right: 0px;
                                                border-right: 2px solid #009ee2 !important; 
                                                top: 0px ;
                                                height: 100%;
                                                    @media (max-width: 991px) {
                                                        display: none;
                                                    }
                                                    @media (max-width: 575.98px) {
                                                        &::before{
                                                            left: 93%;
                                                        }
                                                    }
                                            }
                                            &:hover{
                                                color: #009ee2; 
                                            }
                                            &:hover,&:active{
                                                
                                                &:before {
                                                    position: absolute;
                                                    content: "";
                                                    right: -17px;
                                                    border-right: 2px solid #009ee2;
                                                    top: -8px;
                                                    height: 75%;
                                                    border-radius: 1px;
                                                    transform: rotate(315deg);
                                                    @media (max-width: 991px) {
                                                        border-right: 0px !important;
                                                    }
                                                }
                                                &:after {
                                                    position: absolute !important;
                                                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMTIxLjgwNSAxMjEuODA0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCBkPSJNNy4zMDggNjguMjExaDEwNy4xODhhNy4zMDkgNy4zMDkgMCAwIDAgNy4zMDktNy4zMSA3LjMwOCA3LjMwOCAwIDAgMC03LjMwOS03LjMwOUg3LjMwOGE3LjMxIDcuMzEgMCAwIDAgMCAxNC42MTl6IiBmaWxsPSIjMDAzNTZmIiBvcGFjaXR5PSIxIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIj48L3BhdGg+PC9nPjwvc3ZnPg==z") !important;
                                                    content: "";
                                                    background-repeat: no-repeat;
                                                    background-size: cover;
                                                    border-right: 2px solid #009ee2 !important;
                                                    top: auto !important;
                                                    height: 74% !important;
                                                    width: 0px;
                                                    bottom: -8px;
                                                    display: block;
                                                    background-color: #575859;
                                                    transform: rotate(45deg);
                                                    right: -17px !important;
                                                    
                                                    @media (max-width: 991px) {
                                                        border-right: 0px !important; opacity: 0; height: 0px;
                                                        right: 0px;
                                                    }
                                                }
                                            }
                                    }
                                }
                                .navbar-level-3{
                                    display: none;
                                    position: absolute;  
                                    top: 0; 
                                    left: 100%; 
                                    width: 900px; 
                                    padding-left: 60px;
                                    column-count: 2;
                                    @media (max-width: 1440.98px) {
                                        padding-left: 50px; column-count: 1;
                                    }
                                    @media (max-width: 1199.98px) {
                                        padding-left: 40px;
                                    }
                                    @media (max-width: 991.98px) {
                                        display: block;
                                        position: inherit;
                                        background: #fff;
                                        z-index: 9999;
                                        min-height: auto;
                                        padding: 0px;
                                        width: 100%;
                                        margin-bottom: 10px;
                                        left: 0;
                                    }
                                    li{
                                        padding: 0px 0px;
                                        @media (max-width: 767.98px) {
                                            max-width: 70%;
                                        }
                                        @media (max-width: 991.98px) {
                                            border-bottom: none !important; max-width: 100%;
                                        }
                                        &:hover,&:active,&:focus{
                                            .link-action{
                                                color: #009ee2 !important;
                                            }
                                        }
                                        
                                        .link-action{
                                            font-size: 17px; font-weight: 400 !important; line-height: 25px; padding: 20px 0px; color: #00356f;
                                            @media (max-width: 991.98px) {
                                                padding: 8px 16px;  font-weight: 600 !important; 
                                                
                                            }
                                            &:after,&:before{
                                                height: 0%; display: none;
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                        .container{
                            @media (max-width: 991.98px) {
                                padding: 0px 0px;
                            }
                        }
                        &.active{
                            
                              &::after{
                                background-color: $c-light-cyn-blue; opacity: 1 !important;
                              }
                            }
                    }
                }
                
                ul{
                    li{
                        
                        &.sub-here{
                            @media (max-width: 991.98px) {
                                position: relative;
                                .link-action{
                                    width: 94% !important;
                                    @media (max-width: 575.98px) {
                                        width: 92% !important;
                                    }
                                    @media (max-width: 480.98px) {
                                        width: 88% !important;
                                    }
                                }
                            }
                            
                            .redbox{
                                display: none;
                                @media (max-width: 991.98px) {
                                height: 7px;
                                width: 25px;
                                display: inline-block;
                                position: relative;
                                    &:before{
                                        content: "";
                                        width: 17px;
                                        height: 3px;
                                        background-color: #00356f;
                                        position: absolute;
                                        top: 0px;
                                        left: auto;
                                        right: 0px;
                                        transition: all 0.5s ease-in-out;
                                    }
                                    &:after{
                                        content: "";
                                        width: 17px;
                                        height: 3px;
                                        background-color: #00356f;
                                        position: absolute;
                                        top: 0px;
                                        left: auto;
                                        right: 00px;
                                        transform: rotate(90deg);
                                        transition: all 0.5s ease;
                                    }
                                }
                            }
                            &.open{
                                .redbox{
                                    &::after{
                                        transform: rotate(0deg);
                                    }
                                }
                            }
                            .bluebox{
                                display: none;
                                @media (max-width: 991.98px) {
                                height: 7px;
                                width: 25px;
                                display: inline-block;
                                
                                position: relative;
                                    &:before{
                                        content: "";
                                        width: 17px;
                                        height: 3px;
                                        background-color: #00356f;
                                        position: absolute;
                                        top: 0px;
                                        left: auto;
                                        right: 0px;
                                        transition: all 0.5s ease-in-out;
                                    }
                                    &:after{
                                        content: "";
                                        width: 17px;
                                        height: 3px;
                                        background-color: #00356f;
                                        position: absolute;
                                        top: 0px;
                                        left: auto;
                                        right: 00px;
                                        transform: rotate(90deg);
                                        transition: all 0.5s ease;
                                    }
                                }
                            }
                            &.subNav.open{
                                .bluebox{
                                    &::after{
                                        transform: rotate(0deg);
                                    }
                                }
                            }
                        }
                       
                    }
                }
                
            
            }
}

    .navbar-fixed-top{
        
        .nav-level-1{
            li{
                position: relative;
                
                &:hover{
                    .nav-level-2{
                        display: block; 
                    }
                }
                &:hover{
                    > .link-action{
                        color: #009ee2 !important;
                    }
                }
                
                li a {
                    color: #58595b;
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    position: relative;
                    text-transform: uppercase;
                    padding: 42px 0px;
                    display: inline-block;
                    &:hover {
                        background: #fff; color: #009ee2;
                    }
                    &:active,&:focus{
                        color: #009ee2;
                    }
                    &:after{
                        display: none !important;
                    }
                }
                .nav-level-2{
                    display: none;
                    position: absolute;
                    top: 100%;
                    z-index: 9999;
                    background-color: #575859;
                    
                    border-top: none;
                    border-bottom: 4px solid #009ee2;
                    top:99px;
                    padding: 10px 0px;
                    text-align: left;
                    min-width: 265px;
                    max-width: 265px;
                    @media (max-width: 1399.98px) {
                        min-width: 215px;
                        max-width: 215px;
                        
                    }
                    li{
                        display: block; padding: 0px 15px; color: #fff;
                        &.has-sub-nav{
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16"  height="16" viewBox="0 0 24 24" fill="%23ffffff"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>');
                            background-repeat: no-repeat; 
                            fill:#fff;
                            background-size: 12px auto;
                            background-size: 12px auto;
                            background-position: center right 10px;
                            padding-right: 25px;
                        }
                        @media (max-width: 1399.98px) {
                            padding: 0px 10px;
                        }
                        
                        &:hover{
                            .nav-level-3{
                                display: block; 
                            }
                        }
                        
                        
                        a{
                            padding: 13px 0px; text-transform: capitalize; font-size: 14px; cursor: pointer; background-color: transparent; position: relative; color: #fff !important;
                        }
                        .nav-level-3{
                            display: none;
                            position: absolute;
                            content: "";
                            left: 260px;
                            top: 0;
                            background-color: #575859;
                            border: 1px solid $c-dark-grayish1;
                            border-bottom: 4px solid #009ee2;
                            min-width: 265px;
                            max-width: 265px;
                            left: 100%;
                            @media (max-width: 1399.98px) {
                                min-width: 215px;
                                max-width: 215px;
                                left: 100%;
                            }
                            li{
                                &:hover{
                                    .nav-level-4{
                                        display: block; 
                                    }
                                }
                            }

                            .nav-level-4{
                                display: none;
                                position: absolute;
                                content: "";
                                left: 260px;
                                top: 0;
                                background-color: #575859;
                                border: 1px solid $c-dark-grayish1;
                                border-bottom: 4px solid #009ee2;
                                min-width: 265px;
                                max-width: 265px;
                                left: 100%;
                            }
                            
                        }
                        li{
                            color: #fff !important;
                            &:hover, &:active{
                                color: #009ee2;
                            }
                        }
                    }
                }
            
            }
            li:active{

            }
        }

        li{
            &:hover, &:active{
                color: #009ee2;
            }
        }
        .sub-here{
            .link-action{
                &::after{
                    display: none !important; opacity: 0 !important;
                }
            }
            
        }
        
        li a {
        color: #58595b;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1px;
        position: relative;
        text-transform: uppercase;
        padding: 42px 0px;
        display: inline-block;
        &:hover, &:active{
            color: #009ee2;
        }
    }
  
    }

    .separator{
        .link-action{
            &::after{
                display: none !important; opacity: 0 !important;
            } 
        }
    }

.inner-wrap{
    min-height: 500px;
}

.weoffer-bgdark li {
    color: #fff;
}

// Apple page Navbar condition 
ul.isApplePages{
    li.main_menu, li.separator{
        display: none !important;
    }
  }
ul{
li.main_menu{
    &.apple_page, &.page_contactus{
        display: none !important;
    }
}
}
  @media (min-width: 991.98px) {
    ul.isApplePages{
        li.main_menu{
            &.apple_page, &.page_contactus, &.page_whatwedo{
                display: inline-block !important;
            }
        }
      }
  }
  @media (max-width: 991.98px) {
    ul.isApplePages{
        li.main_menu{
            &.apple_page, &.page_contactus, &.page_whatwedo{
                display:block !important;
            }
        }
      }
  }